import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import Horse from "@/mixins/Horse.js"
import PlanningMonte from "@/mixins/PlanningMonte.js"
import ReponseTransformer from '@/assets/js/dexie/transformers/ReponseTransformer.js'
import SeasonTransformer from '@/assets/js/dexie/transformers/SeasonTransformer.js'
import SeasonMareTransformer from '@/assets/js/dexie/transformers/SeasonMareTransformer.js'
import SeasonMareStallionTransformer from '@/assets/js/dexie/transformers/SeasonMareStallionTransformer.js'
import ActeTransformer from '@/assets/js/dexie/transformers/ActeTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import ContractAvenantTransformer from '@/assets/js/dexie/transformers/ContractAvenantTransformer.js'

import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import TierTransformer from '../assets/js/dexie/transformers/TierTransformer.js'

import SeasonMareStallionCleaner from '@/assets/js/cache/cleaners/SeasonMareStallionCleaner'

var GynecologieMixin = {
    data() {
        return {
			actes_type_saillie: [
                "REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS",
                "REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL",
                "REPRO_INSMINATION_EN_RFRIGR",
                "REPRO_INSMINATION_ARTIFICIELLE_EN_RFRIGR_TRANSPORT",
                "REPRO_SAILLIE_EN_MAIN",
                "REPRO_SAILLIE_EN_LIBERT",
			],
			actes_type_gestation: [
				"REPRO_DIAGNOSTIC_DE_GESTATION"
			],
			actes_type_collecte: [
				"REPRO_COLLECTE_DEMBRYON"
			],
			actes_type_transplantation: [
				"REPRO_TRANSPLANTATION_EMBRYONNAIRE"
			],
			actes_type_poulinage: [
				"REPRO_POULINAGE"
			],
			constatation_gestation: [
				"DG+",
				"DG++"
			],
			constatation_gestation_negative: [
				"DG-",
				"Avortée",
				"Coulée"
			]
        }
    },
    mixins: [Horse, PlanningMonte],
    methods: {
        loadSeasons: async function(contract_config = false, sortByDate = false) {
            return this.$storage.db.t('season')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
            	if(contract_config) {
            		return col.transform(new SeasonTransformer('withContractsConfigStallion'))
            	}
                return col.transform(new SeasonTransformer())
            })
			.then(res => {
				if(sortByDate){
					return res.sort((a, b) => a.season_start - b.season_start)
				}
				return res
			})
        },
        loadSeasonById: async function(season_id) {
            return this.$storage.db.t('season')
            .then(table => {
                return table.get(parseInt(season_id))
            })
            .then(season => {
                return SeasonTransformer.process(season)
            })
        },
        addSeason: async function(season_label, season_start, season_end, season_default, season_use_global_number_template, numbertemplate_prefix, numbertemplate_pattern) {
            const table = await this.$storage.db.t('season')
			const season_id = Common.getNegativeId()

			await table.add({
				season_id: season_id,
				season_label: season_label,
				season_start: season_start,
				season_end: season_end,
				season_default: season_default,
				season_valide: 1,
				season_use_global_number_template: season_use_global_number_template
			})
			
			await this.checkAndFixDefaultSeason(season_id)

			if(season_use_global_number_template == true)
			{
				//J'ajoute la numérotation globale
				await this.$storage.db.t('number_template')
				.then(table => {
						return table.add({
						numbertemplate_id: Common.getNegativeId(),
						numbertemplate_prefix: numbertemplate_prefix,
						numbertemplate_pattern: numbertemplate_pattern,
						numbertemplate_valide: 1,
						numberable_id: season_id,
						numberable_type: 'App\\Model\\Season',
						numberable_field: 'seasonPrefix',
						numbertemplate_licencekey: Constants.USER_LICENCE_KEY
					})
				})
			}

			return true
        },
        editSeason: async function(season_id, data) {
            let season = await this.$storage.db.t('season')
            .then(table => {
                return table.update(parseInt(season_id), data)
			})
			.then(async () => {
				return this.checkAndFixDefaultSeason(season_id)
			})
			.then(() => {
				return true
			})

			if(this.$store.state.userAccess.hasFacturationAccess
				|| this.$store.state.userAccess.hasEntitiesAccess
			) {
				await this.$storage.db.t('number_template')
				.then(table => {
					return table.where({numberable_id: parseInt(season_id), numberable_type: 'App\\Model\\Season'}).invalid()
				})

				if(data.season_use_global_number_template == true) {
					//J'ajoute la numérotation globale
					await this.$storage.db.t('number_template')
					.then(table => {
							return table.add({
							numbertemplate_id: Common.getNegativeId(),
							numbertemplate_prefix: data.numbertemplate_prefix,
							numbertemplate_pattern: data.numbertemplate_pattern,
							numbertemplate_valide: 1,
							numberable_id: parseInt(season_id),
							numberable_type: 'App\\Model\\Season',
							numberable_field: 'seasonPrefix',
							numbertemplate_licencekey: Constants.USER_LICENCE_KEY
						})
					})
				}
			}
			return season 
		},
		duplicateSeason: async function(season_id, params){
        	const url = this.constructRoute(Constants.SEASON_DUPLICATE_URL, {season_id: season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("GynecologieMixin::duplicateSeason", url, params, false)
            .catch(error => {
                console.error("GynecologieMixin::duplicateSeason => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
		},
		/**
		 * Vérifie qu'il y a toujours une saison par défaut après la
		 * modification de la saison season_id et si ce n'est pas le
		 * cas, on met cette dernière saison modifiée en saison par défaut
		 * @param {String|Number} season_id 
		 */
		checkAndFixDefaultSeason: async function(season_id) {
			const table = await this.$storage.db.t('season')
			const hasDefaultSeason = await table
			.filter(season => (season.season_default == 1))
			.count()

			if (!hasDefaultSeason) {
				await table.update(parseInt(season_id), {
					season_default: true
				})
			}
		},
        editAllSeasonDefault: async function(data) {
            return this.$storage.db.t('season')
            .then(table => {
                return table.filter(season => (season.season_default == 1)).modify(data)
            })
        },
        loadSeasonMareBySeason: async function(season_id) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table
                .where({seasonmare_season: parseInt(season_id)})
            })
            .then(col => {
                return col.transform(new SeasonMareTransformer('seasonMareOnlyHorse'))
            })
        },
        loadSeasonMareHorse: async function(horse_id) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table
                .where({seasonmare_horse: horse_id})
            })
            .then(col => {
                return col.transform(new SeasonMareTransformer('withSeasonMareStallions'))
            })
        },
        editSeasonMare: async function(seasonmare_id, data) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table.update(parseInt(seasonmare_id), data)
            })
        },
        addHorseSeasonMare: async function(seasonmare_season, seasonmare_horse, seasonmare_commentary) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table.add({
                    seasonmare_id: Common.getNegativeId(),
                    seasonmare_season: seasonmare_season,
                    seasonmare_horse: seasonmare_horse,
                    seasonmare_commentary: seasonmare_commentary,
                    seasonmare_valide: 1
                })
            })
        },
        addHorseCurrentSeasonMare: async function(seasonmare_horse) {
            let season = await this.$storage.db.t('season')
            .then(table => {
                return table.filter(season => (season.season_default == 1))
                .first()
            })

            return this.$storage.db.t('season_mare')
            .then(table => {
                return table.add({
                    seasonmare_id: Common.getNegativeId(),
                    seasonmare_season: season.season_id,
                    seasonmare_horse: seasonmare_horse,
                    seasonmare_commentary: '',
                    seasonmare_valide: 1
                })
            })
        },
        addHorseToSeason: async function(seasonmare_horse, season_id) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table.add({
                    seasonmare_id: Common.getNegativeId(),
                    seasonmare_season: season_id,
                    seasonmare_horse: seasonmare_horse,
                    seasonmare_commentary: '',
                    seasonmare_valide: 1
                })
            })
        },
        loadSeasonMareStallionBySeasonHorse: async function(horse_id, season_id, with_archive = true) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table
                .where({seasonmare_season: season_id, seasonmare_horse: horse_id})
            })
            .then(col => {
            	if(with_archive)
	                return col.transform(new SeasonMareTransformer('withSeasonMareStallions'))
	            return col.transform(new SeasonMareTransformer('withSeasonMareStallionsWithoutArchive'))
            })
        },
        loadSeasonMareBySeasonHorse: async function(horse_id, season_id) {
            return this.$storage.db.t('season_mare')
            .then(table => {
                return table
                .where({seasonmare_season: season_id, seasonmare_horse: horse_id})
            })
            .then(col => {
            	return col.transform(new SeasonMareTransformer('light'))
            })
        },
        loadCurrentSeasonMareHorse: async function(horse_id) {
            let season = await this.$storage.db.t('season')
            .then(table => {
                return table.filter(season => (season.season_default == 1))
                .first()
            })

            return this.$storage.db.t('season_mare')
            .then(table => {
                return table
                .where({seasonmare_season: season.season_id, seasonmare_horse: horse_id})
            })
            .then(col => {
                return col.transform(new SeasonMareTransformer('light'))
            })
		},
		getSeasonMareById: async function(seasonmare_id) {
			return this.$storage.db.t('season_mare')
			.then(table => {
				return table.get(parseInt(seasonmare_id))
			})
		},
		getHorseDpsStatus: async function() {
        	const url = this.constructRoute(Constants.HORSE_DPS_STATUS_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("GynecologieMixin::getHorseDpsStatus", url)
            .catch(error => {
                console.error("GynecologieMixin::getHorseDpsStatus => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
		},
		getOrCreateSeasonMareStallionHorse: async function(mare_id, stallion_id, date) {
			mare_id = parseInt(mare_id)
			stallion_id = parseInt(stallion_id)

			// Get or create de la saison
            let season = await this.getCurrentSeason(date)

			if(season.length === 0) {
				// Créer une saison du 01/01 au 31/12 pour ceux qui n'ont pas la repro
				const year = date.getFullYear()
				const start = Date.parseTz(`${year}-01-01 00:00:00`)
				const end = Date.parseTz(`${year}-12-31 23:59:59`)

				await this.addSeason(year, start, end, false)

				season = await this.getCurrentSeason(date)
			}

			season = season[0]

			// Get or create du season_mare
			let seasonmare_id = await this.$storage.db.t('season_mare')
			.then(table => {
				return table
				.where({
					seasonmare_season: season.season_id,
					seasonmare_horse: mare_id
				})
				.primaryKeys()
			})
			.then(seasonmare_ids => {
				return seasonmare_ids.length > 0 ? seasonmare_ids[0] : null
			})

			if(!seasonmare_id) {
				seasonmare_id = await this.addHorseToSeason(mare_id, season.season_id)
			}

			// Get or create du season_mare_stallion
			let seasonmarestallion_id = await this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.where({
					seasonmarestallion_seasonmare: seasonmare_id,
					seasonmarestallion_horse: stallion_id
				})
				.and((sms) => {
					return sms.seasonmarestallion_porteuse === null
				})
				.primaryKeys()
			})
			.then(seasonmarestallion_ids => {
				return seasonmarestallion_ids.length > 0 ? seasonmarestallion_ids[0] : null
			})

			if(!seasonmarestallion_id) {
				await this.editAllSeasonMareStallionDefault(
					seasonmare_id,
					{ seasonmarestallion_default: false }
				)

				seasonmarestallion_id = await this.addSeasonMareStallion(seasonmare_id, stallion_id, null, true)
			}

			return seasonmarestallion_id
		},
        addSeasonMareStallion: function(seasonmarestallion_seasonmare, seasonmarestallion_horse, seasonmarestallion_dps, seasonmarestallion_default, porteuse = null) {
			return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table.add({
                    seasonmarestallion_id: Common.getNegativeId(),
                    seasonmarestallion_seasonmare: seasonmarestallion_seasonmare,
                    seasonmarestallion_horse: seasonmarestallion_horse,
                    seasonmarestallion_dps: seasonmarestallion_dps,
                    seasonmarestallion_default: seasonmarestallion_default,
					seasonmarestallion_porteuse: porteuse,
                    seasonmarestallion_valide: 1
                })
            })
        },
        editSeasonMareStallion: async function(seasonmarestallion_id, data) {
            return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table.update(parseInt(seasonmarestallion_id), data)
            })
        },
        editAllSeasonMareStallionDefault: async function(seasonmare_id, data) {
			seasonmare_id = parseInt(seasonmare_id)

            return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table.where({
					seasonmarestallion_seasonmare: seasonmare_id
				})
				.and((sms) => (sms.seasonmarestallion_porteuse === null))
				.or('seasonmarestallion_porteuse').equals(seasonmare_id)
				.and((sms) => (sms.seasonmarestallion_default == 1))
				.modify(data)
            })
        },
		/**
		 * NON UTILISE CAR CALCUL COTE SERVEUR
		 * Mise à jour des FK d'un season_mare_stallion en regardant tous ses actes
		 * @param {Number} seasonmarestallion_id ID de SeasonMareStallion
		 */
		updateSeasonMareStallionStatus: async function(seasonmarestallion_id) {
			const season_mare_stallion = await this.loadSeasonMareStallion(seasonmarestallion_id)

			if(!season_mare_stallion) {
				return
			}

			// Récupérer tous les IDs des actes liés au season_mare_stallion
			const acte_ids = await this.$storage.db.t('reponse')
			.then(table => {
				return table.where({
					'reponse_type': 'horse_actes',
					'reponse_data_hidden.type': 'season_mare_stallion',
					'reponse_data_hidden.id': seasonmarestallion_id
				})
				.toArray()
			})
			.then(reponses => {
				return reponses.map((r) => (r.reponse_fk))
			})

			// Récupérer les actes validés triés par date
			const actes = await this.$storage.db.t('horse_actes')
			.then(table => {
				return table.where(':id').anyOf(acte_ids)
				.and(acte => (acte.actes_actesstatut === 1))
				.sortBy('actes_date')
			})

			// Récupérer les IDs des différents types d'actes de REPRO
			const actes_type_saillie_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(this.actes_type_saillie)
				.primaryKeys()
			})

			const actes_type_gestation_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(this.actes_type_gestation)
				.primaryKeys()
			})

			const actes_type_collecte_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(this.actes_type_collecte)
				.primaryKeys()
			})

			const actes_type_transplantation_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(this.actes_type_transplantation)
				.primaryKeys()
			})

			const actes_type_poulinage_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(this.actes_type_poulinage)
				.primaryKeys()
			})

			// Initialisation des valeurs à calculer
			let sms = {
				seasonmarestallion_porteuse: null,
				seasonmarestallion_ps: null,
				seasonmarestallion_ds: null,
				seasonmarestallion_dg: null,
				seasonmarestallion_collecte: null,
				seasonmarestallion_transplantation: null,
				seasonmarestallion_poulinage: null,
				seasonmarestallion_status: null,
			}

			actes.forEach(acte => {
				// Si un des actes avec le couple n'est pas la jument du season_mare alors c'est la mère porteuse du couple
				if(acte.actes_horse !== season_mare_stallion.mare.seasonmare_horse) {
					sms.seasonmarestallion_porteuse = season_mare_stallion.mare.seasonmare_id
				}

				// Si type === saillie -> seasonmarestallion_ps
				if(actes_type_saillie_ids.includes(acte.actes_actestype)) {
					if(!sms.seasonmarestallion_ps) {
						sms.seasonmarestallion_ps = acte.actes_id
					}

					// Si type === saillie -> seasonmarestallion_ds
					sms.seasonmarestallion_ds = acte.actes_id
				}
				// Si type === DG -> seasonmarestallion_dg
				else if(actes_type_gestation_ids.includes(acte.actes_actestype)) {
					sms.seasonmarestallion_dg = acte.actes_id
				}
				// Si type === collecte -> seasonmarestallion_collecte
				else if(actes_type_collecte_ids.includes(acte.actes_actestype)) {
					sms.seasonmarestallion_collecte = acte.actes_id
				}
				// Si type === transplantation -> seasonmarestallion_transplantation
				else if(actes_type_transplantation_ids.includes(acte.actes_actestype)) {
					sms.seasonmarestallion_transplantation = acte.actes_id
				}
				// Si type === poulinage -> seasonmarestallion_poulinage
				else if(actes_type_poulinage_ids.includes(acte.actes_actestype)) {
					sms.seasonmarestallion_poulinage = acte.actes_id
				}

			})

			sms.seasonmarestallion_status = await this.getSeasonMareStallionStatus(sms)

			await this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.update(seasonmarestallion_id, sms)
			})
		},
		/**
		 * Récupèrer l'état de la jument sous forme de chaine de caractère
		 * @param {Object} sms Objet contenant les valeurs calculées du season_mare_stallion toujours pas sauvegardées en BDD
		 * @returns {String} String en français indiquant l'état du couple
		 */
		getSeasonMareStallionStatus: async function(sms) {
			// ALGORITHME DE CALCUL DE L'ETAT D'UN COUPLE
			// 	if ("seasonmarestallion_poulain"!=null) {
			// 		return "la réponse de l’acte poulinage + info poulain si Poulain vivant";
			// 	}else {
			// 		if("date collecte embryon > date dernier saut") return "Collectée le XX";
			// 		else {
			// 			if("date dernier dg" >= "date dernier saut") return "le résultat du DG";
			
			// 			else {
			// 				if("porteuse==id de la porteuse && date de transplantation >= date de dernier saut") return "Transplanté le XX d'un embryon de nom de la donneuse / Etalon ";
			// 				else {
			// 					if("date de dernier saut" != null) return "Saillie le 15/04";
			
			// 					else {
			// 						return "non saillie";
			// 					}
			// 				}
			// 			}
			
			// 		}
			// 	}

			if(sms.seasonmarestallion_poulinage) {
				return this.getFormattedPoulinage(sms.seasonmarestallion_poulinage)
			}
			else {
				let acte_collecte = null
				let acte_ds = null

				if(sms.seasonmarestallion_collecte) {
					acte_collecte = await this.getActeFromId(sms.seasonmarestallion_collecte, '')
				}

				if(sms.seasonmarestallion_ds) {
					acte_ds = await this.getActeFromId(sms.seasonmarestallion_ds, '')
				}

				if(acte_collecte && acte_ds && (acte_collecte.actes_date > acte_ds.actes_date)) {
					return `Collectée le ${this.formatStatusDate(acte_ds.actes_date)}`
				}
				else {
					let acte_dg = null

					if(sms.seasonmarestallion_dg) {
						acte_dg = await this.getActeFromId(sms.seasonmarestallion_dg, '')
					}

					if(acte_dg && acte_ds && (acte_dg >= acte_ds)) {
						return this.getFormattedDG(acte_dg.actes_id)
					}
					else {
						let acte_transplantation = null

						if(sms.seasonmarestallion_transplantation) {
							acte_transplantation = await this.getActeFromId(sms.seasonmarestallion_transplantation, '')
						}

						if(sms.seasonmarestallion_porteuse && acte_transplantation && acte_ds && (acte_transplantation.actes_date >= acte_ds.actes_date)) {
							// C'est inutile de spécifier la jument est l'étalon puisque c'est le season_mare_stallion de donneuse/étalon(porteuse)
							return `Transplanté le ${this.formatStatusDate(acte_transplantation.actes_date)}`
						}
						else {
							if(acte_ds) {
								return `Saillie le ${this.formatStatusDate(acte_ds.actes_date)}`
							}
							else {
								// Non saillie est différent de Vide pour les spécialistes
								return 'Non saillie'
							}
						}
					}
				}
			}
		},
		getFormattedPoulinage: async function(actes_id) {
			let formatted = null

			const reponses_poulinage = await this.$storage.db.t('reponse')
			.then(table => {
				return table.where({
					reponse_type: 'horse_actes',
					reponse_fk: actes_id
				})
				.transform(new ReponseTransformer('withQuestion'))
			})

			reponses_poulinage.forEach((reponse) => {
				if(reponse.question.question_code == 'Résultat') {
					formatted = reponse.reponse_data[0]
				}
			})

			return formatted
		},
		getFormattedDG: async function(actes_id) {
			let formatted = null

			const reponses_dg = await this.$storage.db.t('reponse')
			.then(table => {
				return table.where({
					reponse_type: 'horse_actes',
					reponse_fk: actes_id
				})
				.transform(new ReponseTransformer('withQuestion'))
			})

			reponses_dg.forEach((reponse) => {
				if(reponse.question.question_code == 'Résultat') {
					formatted = reponse.reponse_data[0]
				}
			})

			return formatted
		},
		formatStatusDate(date) {
			const month = (date.getMonth() + 1).toString().padStart(2, '0')

			return `${date.getDate()}/${month}/${date.getFullYear()}`
		},
        loadSeasonMareStallion: async function(seasonmarestallion_id) {
            let seasonmarestallion = await this.$storage.db.t('season_mare_stallion')
            .then(table => {
				if (seasonmarestallion_id) {
					return table.where('seasonmarestallion_id').equals(parseInt(seasonmarestallion_id))
				}
				else {
					return table.toCollection()
				}
            })
            .then(col => {  
                return col.transform(new SeasonMareStallionTransformer('withMareAndHorse'))
            })

            return seasonmarestallion_id ? seasonmarestallion[0] : seasonmarestallion
		},
		loadSeasonsUnusedForContracts: async function() {
            return this.$storage.db.t('season')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new SeasonTransformer('WithContracts'))
			})
			.then(res => {
				return res.filter(season => season.contracts_config.length === 0)
			})	
        },
        etatJumenterie: async function(season_id) {
			// Charger les season_mare_stallions à afficher
            let season_mares = await this.$storage.db.t('season_mare')
            .then(table => {
                return table.where('seasonmare_season').equals(parseInt(season_id)).toArray()
            })

			const season_mare_ids = season_mares.map(sm => sm.seasonmare_id)

            let sms = await this.$storage.db.t('season_mare_stallion')
            .then(table => {
				return table.where('seasonmarestallion_seasonmare').anyOf(season_mare_ids)
				.and(sms => (!sms.seasonmarestallion_archive))
				.toArray()
			})

			let sms_ids = sms.map(season_mare_stallions => season_mare_stallions.seasonmarestallion_id)
			let mailtracking = await this.$storage.db.t('mail_tracking')
				.then(table => {
					return table.where('mailtracking_fk').anyOf(sms_ids)
					.and(mt => mt.mailtracking_fktype == 'seasonmarestallion')
					.toArray()
				})

            let seasonmarestallion = []
			seasonmarestallion = this.getMoreRecentSeasonMareStallion(sms, seasonmarestallion)

			// Récupérer les IDs des chevaux et des actes
			let horses_ids = season_mares.map(sm => sm.seasonmare_horse)

			let actes_ids = []
			const seasonmare_porteuse_ids = []
			seasonmarestallion.forEach(sms => {
				horses_ids.push(sms.seasonmarestallion_horse)

				if(sms.seasonmarestallion_porteuse) {
					seasonmare_porteuse_ids.push(sms.seasonmarestallion_porteuse)
				}

				actes_ids.push(sms.seasonmarestallion_ps)
				actes_ids.push(sms.seasonmarestallion_ds)
				actes_ids.push(sms.seasonmarestallion_poulinage)
				actes_ids.push(sms.seasonmarestallion_transplantation)
			})

			// Ajouter les horse_id des porteuses
			let porteuse_season_mare_horse_ids = {}
			if(seasonmare_porteuse_ids.length > 0) {
				await this.$storage.db.t('season_mare')
				.then(table => {
					return table.where('seasonmare_id').anyOf(seasonmare_porteuse_ids)
					.each(sm => {
						horses_ids.push(sm.seasonmare_horse)
						porteuse_season_mare_horse_ids[sm.seasonmare_id] = sm.seasonmare_horse
					})
				})
			}

			// Charger les actes pour afficher les dates
			actes_ids = actes_ids.filter((a) => (a))
			const actes = await this.$storage.db.t('horse_actes')
			.then(table => {
				return table.where('actes_id').anyOf(actes_ids).toArray()
			})

			// Charger les chevaux (juments et étalons)
			horses_ids = horses_ids.filter((h) => (h))
			const horses = await this.$storage.db.t('horse')
			.then(table => {
				return table.where('horse_id').anyOf(horses_ids).toArray()
			})

			// Faire un objet liant le seasonmare_id au horse
			let season_mare_horses = {}
			season_mares.forEach(sm => {
				season_mare_horses[sm.seasonmare_id] = horses.find(h => (h.horse_id === sm.seasonmare_horse))
			})

			// Charger les tiers de la jument
			let tiers_horses = []
			let tiers = []
			let tiers_stationnement = []
			if(this.$store.state.userAccess.hasFacturationAccess
				|| this.$store.state.userAccess.hasEntitiesAccess
				|| this.$store.state.userAccess.hasBreedingAccess
			) {
				const mares_ids = horses.filter(h => (h.horse_sexe === 'F'))
					.map(h => (h.horse_id))
				tiers_horses = await this.$storage.db.t('tiers_horse')
				.then(table => {
					return table.where('tiershorse_horse').anyOf(mares_ids)
					.and(th => (th.tiershorse_valide))
					.toArray()
				})
				const tiers_ids = tiers_horses.map(th => (th.tiershorse_tiers))
				tiers = await this.$storage.db.t('tiers')
				.then(table => {
					return table.where('tiers_id').anyOf(tiers_ids).toArray()
				})

				const tiers_stationnement_ids = horses.filter(h => (h.horse_sexe === 'F')).map(h => (h.horse_lieustationnement)).filter(id => (!!id))
				tiers_stationnement = await this.$storage.db.t('tiers')
				.then(table => {
					return table.where('tiers_id').anyOf(tiers_stationnement_ids)
				})
				.then(col => {
					return col.transform(new TierTransformer('withPhone'))
				})
			}

			// Récupération de la saison précédente
			let previous_season = await this.getPreviousSeasonFromSeason(season_id)

			let seasonmares_pseason = []
			let sms_pseason = []
			let stallions_ids_pseason = []
			let actes_transplantation_ids_pseason = []
			let actes_transplantation_pseason = []
			let stallions_pseason = []
			if(previous_season) {
				// Récupération des season_mares de la saison précédente
				seasonmares_pseason = await this.$storage.db.t('season_mare')
				.then(table => {
					return table.where('seasonmare_season').equals(parseInt(previous_season.season_id)).toArray()
				})

				// Récupération des season_mare_stallions de la saison précédente
				sms_pseason = await this.getAllSeasonMareStallionFromPreviousSeason(previous_season.season_id)

				// Récupération des ids des actes de transplantation de la saison précédente
				actes_transplantation_ids_pseason = sms_pseason.map(sms_ps => sms_ps.seasonmarestallion_transplantation).filter(a => (a))

				// Récupération des actes de transplantation de la saison précédente
				actes_transplantation_pseason = await this.$storage.db.t('horse_actes')
					.then(table => {
						return table.where('actes_id').anyOf(actes_transplantation_ids_pseason).toArray()
					})

				// Récupération des ids des étalons de la saison précédente
				stallions_ids_pseason = sms_pseason.map(sms_ps => sms_ps.seasonmarestallion_horse)

				// Récupération des étalons de la saison précédente
				stallions_pseason = await this.$storage.db.t('horse')
					.then(table => {
						return table.where('horse_id').anyOf(stallions_ids_pseason).toArray()
					})
	
			}
				
			const jumenterie = []

			const mares_ids = horses.filter(h => (h.horse_sexe === 'F'))
			.map(h => (h.horse_id))

			let tiershorses_tiers = await Promise.all( mares_ids.map( async(id) => {
				let res = await this.loadTiersCurrentPart(id)
				res = res.tiers_horse.map (r => r.tiers)
				return {
					tiers: res,
					horse_id: id
				}
			}))

			seasonmarestallion.forEach(sms => {
				const stallion = horses.find(h => (h.horse_id === sms.seasonmarestallion_horse))
				let mare = season_mare_horses[sms.seasonmarestallion_seasonmare]

				if(!stallion || stallion.horse_inactive || !mare || mare.horse_inactive) {
					return
				}

				const porteuse_id = porteuse_season_mare_horse_ids[sms.seasonmarestallion_porteuse]
				let porteuse = null
				const ps = actes.find(a => (a.actes_id === sms.seasonmarestallion_ps))
				const ds = actes.find(a => (a.actes_id === sms.seasonmarestallion_ds))
				const poulinage = actes.find(a => (a.actes_id === sms.seasonmarestallion_poulinage))
				const transplantation = actes.find(a => (a.actes_id === sms.seasonmarestallion_transplantation))
				const season_mare = season_mares.find(sm => sm.seasonmare_id === sms.seasonmarestallion_seasonmare)

				let stallion_pseason = null
				let ds_date_previous_season = null
				let transplantation_previous_season = null
				let dt_pseason = null
				let eleven_month_pseason = null
				let seasonmarestallion_pseason = []

				if(mare) {
					mare.sire_complet = ''
					if(mare.horse_sire && mare.horse_cle) {
						mare.sire_complet = mare.horse_sire + mare.horse_cle
					}

					let horsetiers = tiershorses_tiers.find(th => (th.horse_id === mare.horse_id))
					mare.horse_tiers = horsetiers ? horsetiers.tiers : null

					const horse_lieustationnement = tiers_stationnement.find(t => (t.tiers_id === mare.horse_lieustationnement))
					if(horse_lieustationnement) {
						mare.lieustationnement = horse_lieustationnement.tiers_rs
						mare.lieustationnement_cp = horse_lieustationnement.tiers_postalcode
						mare.lieustationnement_town = horse_lieustationnement.tiers_town
						mare.lieustationnement_phone = horse_lieustationnement.phones.length > 0 ? horse_lieustationnement.phones[0].phone_combine : ''
						mare.lieustationnement_phone_num = horse_lieustationnement.phones.length > 0 ? horse_lieustationnement.phones[0].phone_indicatif.replace('+', '')+horse_lieustationnement.phones[0].phone_numero : ''
						mare.lieustationnement_mail = horse_lieustationnement.tiers_mail
						mare.lieustationnement_id = horse_lieustationnement.tiers_id
					}
					
					if(previous_season) {
						// Récupération des season_mares de la saison précédente de la jument (théoriquement on ne devrait en avaoir qu'une mais il y a des exceptions)
						const seasonmare_ids_previous_season = seasonmares_pseason.map((sm) => {
							if(sm.seasonmare_horse == mare.horse_id) {
								return sm.seasonmare_id
							}
						}).filter(sm => (!!sm))

						// Récupération des season_mare_stallions correspondants
						let sms_ps = sms_pseason.filter(sms => seasonmare_ids_previous_season.includes(sms.seasonmarestallion_seasonmare))

						seasonmarestallion_pseason = this.getMoreRecentSeasonMareStallion(sms_ps, seasonmarestallion_pseason)

						if(seasonmarestallion_pseason.length > 0) {
							stallion_pseason = stallions_pseason.find(h => (h.horse_id === seasonmarestallion_pseason[0].seasonmarestallion_horse))	
							ds_date_previous_season = seasonmarestallion_pseason[0].seasonmarestallion_dds
							transplantation_previous_season = actes_transplantation_pseason.find(a => (a.actes_id === seasonmarestallion_pseason.seasonmarestallion_transplantation))
							
							let last_insemin_previous_season = ds_date_previous_season
							let is_transplantation_previous_season = false

							if(transplantation_previous_season && (!ds_date_previous_season || transplantation.actes_date > ds_date_previous_season)) {
								last_insemin_previous_season = transplantation.actes_date
								is_transplantation_previous_season = true
							}

							if(last_insemin_previous_season) {
								dt_pseason = new Date(last_insemin_previous_season.getTime())
								if(is_transplantation_previous_season) {
									dt_pseason.setDate(dt_pseason.getDate() + 330)
								}
								else {
									dt_pseason.setDate(dt_pseason.getDate() + 338)
								}

								eleven_month_pseason = new Date(last_insemin_previous_season.getTime())
								if(is_transplantation_previous_season) {
									eleven_month_pseason.setDate(eleven_month_pseason.getDate() + 326)
								}
								else {
									eleven_month_pseason.setDate(eleven_month_pseason.getDate() + 334)
								}
							}
						}
					}	
				}
				else {
					mare = {horse_tiers: []}
				}

				if(porteuse_id) {
					porteuse = horses.find((h) => (h.horse_id === porteuse_id))

					// porteuse.sire_complet = ''
					if(porteuse && porteuse.horse_sire && porteuse.horse_cle) {
						porteuse.sire_complet = porteuse.horse_sire + porteuse.horse_cle
					}
					else if(porteuse) {
						porteuse.sire_complet = ''
					}
					else {
						porteuse = {sire_complet: ''}
					}
				}

				let dt = sms.seasonmarestallion_dt
				let eleven_month = null
				let last_insemin = ds
				let is_transplantation = false

				if(transplantation && (!ds || transplantation.actes_date > ds.actes_date)) {
					last_insemin = transplantation
					is_transplantation = true
				}

				if(last_insemin && last_insemin.actes_date && dt) {
					// dt = new Date(last_insemin.actes_date.getTime())
					// if(is_transplantation) {
					// 	dt.setDate(dt.getDate() + 330)
					// }
					// else {
					// 	dt.setDate(dt.getDate() + 338)
					// }

					eleven_month = new Date(last_insemin.actes_date.getTime())
					if(is_transplantation) {
						eleven_month.setDate(eleven_month.getDate() + 326)
					}
					else {
						eleven_month.setDate(eleven_month.getDate() + 334)
					}
				}

				let nb_jours = null;
				if(ds) {
					let date1 = new Date()
					let date2 = ds.actes_date
					date1.setHours(0,0,0,0);
	                date2.setHours(0,0,0,0);
	                let Difference_In_Time = date1.getTime() - date2.getTime(); 
	                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
					nb_jours = Math.round(Difference_In_Days)
				}

				let tracking_dates_sended = []
				let tracking_dates_received = []
				let modellabel = ''
				if(mailtracking) {
					const mts = mailtracking.filter(mt => mt.mailtracking_fk == sms.seasonmarestallion_id)
					if(mts.length > 0) {
						const sorted_mts = _orderBy(mts, ['mailtracking_id'], ['desc'])
						if(sorted_mts && sorted_mts[0]) {
							modellabel = sorted_mts[0].mailtracking_modellabel
						}

						const mts_sent = mts.filter(mt => mt.mailtracking_type == 'mail')
						if(mts_sent) {
							tracking_dates_sended = mts_sent.map(mt => mt.mailtracking_date)
						}

						const mts_received = mts.filter(mt => mt.mailtracking_type == 'received')
						if(mts_received) {
							tracking_dates_received = mts_received.map(mt => mt.mailtracking_date)
						}
					}
				}

				jumenterie.push({
					'seasonmarestallion_id'             : sms.seasonmarestallion_id,
					'seasonmarestallion_seasonmare'     : sms.seasonmarestallion_seasonmare,
					'seasonmarestallion_horse'          : sms.seasonmarestallion_horse,
					'seasonmarestallion_default'        : sms.seasonmarestallion_default,
					'horse'                             : stallion,
					'mare'								: { horse: mare },
					'porteuse'							: porteuse ? { horse: porteuse } : null,
					'seasonmarestallion_state'			: Common.translateSeasonMareStatus(sms.seasonmarestallion_status),
					'seasonmarestallion_dps_date'		: ps ? ps.actes_date : null,
					'seasonmarestallion_dds_date'		: ds ? ds.actes_date : null,
					'seasonmarestallion_dt_date'		: dt,
					'seasonmarestallion_dt_date_elevenmonth': eleven_month,
					'poulinage_date'					: poulinage ? poulinage.actes_date : null,
					'seasonmarestallion_num_dps'		: sms.seasonmarestallion_num_dps,
					'seasonmare_commentary'				: season_mare.seasonmare_commentary,
					'stallion_previous_season'			: stallion_pseason,
					'seasonmarestallion_state_previous_season': seasonmarestallion_pseason[0] ? seasonmarestallion_pseason[0].seasonmarestallion_status : null,
					'seasonmarestallion_dt_date_previous_season': dt_pseason,
					'seasonmarestallion_dt_date_elevenmonth_previous_season': eleven_month_pseason,
					'days_last_saillie'					: nb_jours,
					'modellabel'						: modellabel,
					'tracking_sended_date'				: tracking_dates_sended,
					'tracking_received_date'			: tracking_dates_received
				})
			})

			let season_mare_empty = season_mares.filter(sm => sms.filter(couple => couple.seasonmarestallion_seasonmare == sm.seasonmare_id).length == 0)
			let mare_ids = season_mare_empty.map(sm => sm.seasonmare_horse)
			let mares = await this.getHorsesByIds(mare_ids)

			season_mare_empty.forEach(sm => {
				let mare = mares.find(horse => horse.horse_id == sm.seasonmare_horse)

				// Si cheval supprimé, on break
				if (!mare) return

				// Si cheval déjà porteuse, on break
				let mare_porteuse = Object.values(porteuse_season_mare_horse_ids).find(porteuse_id => porteuse_id == mare.horse_id)
				if (mare_porteuse) return

				let stallion_pseason = null
				let ds_date_previous_season = null
				let transplantation_previous_season = null
				let dt_pseason = null
				let eleven_month_pseason = null
				let seasonmarestallion_pseason = []

				if(previous_season) {
					// Récupération des season_mares de la saison précédente de la jument (théoriquement on ne devrait en avaoir qu'une mais il y a des exceptions)
					const seasonmare_ids_previous_season = seasonmares_pseason.map((sm) => {
						if(sm.seasonmare_horse == mare.horse_id) {
							return sm.seasonmare_id
						}
					}).filter(sm => (!!sm))

					// Récupération des season_mare_stallions correspondants
					let sms_ps = sms_pseason.filter(sms => seasonmare_ids_previous_season.includes(sms.seasonmarestallion_seasonmare))

					seasonmarestallion_pseason = this.getMoreRecentSeasonMareStallion(sms_ps, seasonmarestallion_pseason)

					if(seasonmarestallion_pseason.length > 0) {
						stallion_pseason = stallions_pseason.find(h => (h.horse_id === seasonmarestallion_pseason[0].seasonmarestallion_horse))	
						ds_date_previous_season = seasonmarestallion_pseason[0].seasonmarestallion_dds
						transplantation_previous_season = actes_transplantation_pseason.find(a => (a.actes_id === seasonmarestallion_pseason.seasonmarestallion_transplantation))
						
						let last_insemin_previous_season = ds_date_previous_season
						let is_transplantation_previous_season = false

						if(last_insemin_previous_season) {
							dt_pseason = new Date(last_insemin_previous_season.getTime())
							if(is_transplantation_previous_season) {
								dt_pseason.setDate(dt_pseason.getDate() + 330)
							}
							else {
								dt_pseason.setDate(dt_pseason.getDate() + 338)
							}

							eleven_month_pseason = new Date(last_insemin_previous_season.getTime())
							if(is_transplantation_previous_season) {
								eleven_month_pseason.setDate(eleven_month_pseason.getDate() + 326)
							}
							else {
								eleven_month_pseason.setDate(eleven_month_pseason.getDate() + 334)
							}
						}
					}
				}

				jumenterie.push({
					'seasonmarestallion_id'             : Common.getNegativeId()*-1,
					'seasonmarestallion_seasonmare'     : sm.seasonmare_id,
					'seasonmarestallion_horse'          : null,
					'seasonmarestallion_default'        : 0,
					'horse'                             : { horse_nom: ''},
					'mare'								: { horse: mare },
					'porteuse'							: null,
					'seasonmarestallion_state'			: '',
					'seasonmarestallion_dps_date'		: null,
					'seasonmarestallion_dds_date'		: null,
					'seasonmarestallion_dt_date'		: null,
					'seasonmarestallion_dt_date_elevenmonth': null,
					'poulinage_date'					: null,
					'seasonmarestallion_num_dps'		: '',
					'seasonmare_commentary'				: sm.seasonmare_commentary,
					'stallion_previous_season'			: stallion_pseason,
					'seasonmarestallion_state_previous_season': seasonmarestallion_pseason[0] ? seasonmarestallion_pseason[0].seasonmarestallion_status : null,
					'seasonmarestallion_dt_date_previous_season': dt_pseason,
					'seasonmarestallion_dt_date_elevenmonth_previous_season': eleven_month_pseason,
					'days_last_saillie'					: null,
					'modellabel'						: '',
					'tracking_sended_date'				: null,
					'tracking_received_date'			: null
				})
			})

			return jumenterie
        },
        croisement: async function(season_id) {
        	// Charger les season_mare_stallions à afficher
            let season_mares = await this.$storage.db.t('season_mare')
            .then(table => {
                return table.where('seasonmare_season').equals(parseInt(season_id)).toArray()
            })

			const season_mare_ids = season_mares.map(sm => sm.seasonmare_id)

            const seasonmarestallion = await this.$storage.db.t('season_mare_stallion')
            .then(table => {
				return table.where('seasonmarestallion_seasonmare').anyOf(season_mare_ids)
				.and(sms => (!sms.seasonmarestallion_archive))
				.toArray()
			})

			// Récupérer les IDs des chevaux et des actes
			let horses_ids = season_mares.map(sm => sm.seasonmare_horse)

			let actes_ids = []
			const seasonmare_porteuse_ids = []
			seasonmarestallion.forEach(sms => {
				horses_ids.push(sms.seasonmarestallion_horse)

				if(sms.seasonmarestallion_porteuse) {
					seasonmare_porteuse_ids.push(sms.seasonmarestallion_porteuse)
				}

				actes_ids.push(sms.seasonmarestallion_ps)
				actes_ids.push(sms.seasonmarestallion_ds)
				actes_ids.push(sms.seasonmarestallion_poulinage)
				actes_ids.push(sms.seasonmarestallion_transplantation)
			})

			// Ajouter les horse_id des porteuses
			let porteuse_season_mare_horse_ids = {}
			if(seasonmare_porteuse_ids.length > 0) {
				await this.$storage.db.t('season_mare')
				.then(table => {
					return table.where('seasonmare_id').anyOf(seasonmare_porteuse_ids)
					.each(sm => {
						horses_ids.push(sm.seasonmare_horse)
						porteuse_season_mare_horse_ids[sm.seasonmare_id] = sm.seasonmare_horse
					})
				})
			}

			// Charger les actes pour afficher les dates
			actes_ids = actes_ids.filter((a) => (a))
			const actes = await this.$storage.db.t('horse_actes')
			.then(table => {
				return table.where('actes_id').anyOf(actes_ids).toArray()
			})

			// Charger les chevaux (juments et étalons)
			horses_ids = horses_ids.filter((h) => (h))
			const horses = await this.$storage.db.t('horse')
			.then(table => {
				return table.where('horse_id').anyOf(horses_ids).toArray()
			})

			// Faire un objet liant le seasonmare_id au horse
			let season_mare_horses = {}
			season_mares.forEach(sm => {
				season_mare_horses[sm.seasonmare_id] = horses.find(h => (h.horse_id === sm.seasonmare_horse))
			})

			// Charger les tiers de la jument
			let tiers_horses = []
			let tiers = []
			if(this.$store.state.userAccess.hasFacturationAccess
				|| this.$store.state.userAccess.hasEntitiesAccess
			) {
				const mares_ids = horses.filter(h => (h.horse_sexe === 'F'))
					.map(h => (h.horse_id))
				tiers_horses = await this.$storage.db.t('tiers_horse')
				.then(table => {
					return table.where('tiershorse_horse').anyOf(mares_ids)
					.and(th => (th.tiershorse_valide))
					.toArray()
				})
	
				const tiers_ids = tiers_horses.map(th => (th.tiershorse_tiers))
				tiers = await this.$storage.db.t('tiers')
				.then(table => {
					return table.where('tiers_id').anyOf(tiers_ids).toArray()
				})
			}

			const jumenterie = []

			seasonmarestallion.forEach(sms => {
				const stallion = horses.find(h => (h.horse_id === sms.seasonmarestallion_horse))
				const mare = season_mare_horses[sms.seasonmarestallion_seasonmare]
				const porteuse_id = porteuse_season_mare_horse_ids[sms.seasonmarestallion_porteuse]

				if(((stallion && stallion.horse_inactive) || (mare && mare.horse_inactive)) && !porteuse_id) {
					return
				}

				let porteuse = null
				const ps = actes.find(a => (a.actes_id === sms.seasonmarestallion_ps))
				const ds = actes.find(a => (a.actes_id === sms.seasonmarestallion_ds))
				const poulinage = actes.find(a => (a.actes_id === sms.seasonmarestallion_poulinage))
				const transplantation = actes.find(a => (a.actes_id === sms.seasonmarestallion_transplantation))

				if(mare) {
					mare.sire_complet = ''
					if(mare.horse_sire && mare.horse_cle) {
						mare.sire_complet = mare.horse_sire + mare.horse_cle
					}
					mare.horse_tiers = tiers_horses.filter(th => (th.tiershorse_horse === mare.horse_id))
					.map(th => (th.tiershorse_tiers))
					.map(mare_tiers_id => {
						return tiers.find(t => (t.tiers_id === mare_tiers_id))
					})
					.filter(t => (!!t))
					// .map(t => (t.tiers_rs))
					// .join(', ')
				}

				if(porteuse_id) {
					porteuse = horses.find((h) => (h.horse_id === porteuse_id))
					if((porteuse && porteuse.horse_inactive)) {
						return
					}

					porteuse.sire_complet = ''
					if(porteuse && porteuse.horse_sire && porteuse.horse_cle) {
						porteuse.sire_complet = porteuse.horse_sire + porteuse.horse_cle
					}
				}

				let dt = null
				let eleven_month = null
				let last_insemin = ds
				let is_transplantation = false

				if(transplantation && (!ds || transplantation.actes_date > ds.actes_date)) {
					last_insemin = transplantation
					is_transplantation = true
				}

				if(last_insemin && last_insemin.actes_date) {
					// dt = new Date(last_insemin.actes_date.getTime())
					// if(is_transplantation) {
					// 	dt.setDate(dt.getDate() + 330)
					// }
					// else {
					// 	dt.setDate(dt.getDate() + 338)
					// }
					dt = sms.seasonmarestallion_dt

					eleven_month = new Date(last_insemin.actes_date.getTime())
					if(is_transplantation) {
						eleven_month.setDate(eleven_month.getDate() + 326)
					}
					else {
						eleven_month.setDate(eleven_month.getDate() + 334)
					}
				}

				jumenterie.push({
					'seasonmarestallion_id'             : sms.seasonmarestallion_id,
					'seasonmarestallion_seasonmare'     : sms.seasonmarestallion_seasonmare,
					'seasonmarestallion_horse'          : sms.seasonmarestallion_horse,
					'seasonmarestallion_default'        : sms.seasonmarestallion_default,
					'horse'                             : stallion,
					'mare'								: { horse: mare },
					'porteuse'							: porteuse ? { horse: porteuse } : null,
					'seasonmarestallion_state'			: Common.translateSeasonMareStatus(sms.seasonmarestallion_status),
					'seasonmarestallion_dps_date'		: ps ? ps.actes_date : null,
					'seasonmarestallion_dds_date'		: ds ? ds.actes_date : null,
					'seasonmarestallion_dt_date'		: dt,
					'seasonmarestallion_dt_date_elevenmonth': eleven_month,
					'seasonmarestallion_num_dps'		: sms.seasonmarestallion_num_dps,
					'poulinage_date'					: poulinage ? poulinage.actes_date : null
				})
			})

			return jumenterie
        },
        loadSeasonMareStallionBySeason: async function(horse_id, season_id) {
			const seasonMareIds = await this.$storage.db.t('season_mare')
			.then(table => {
				return table.where({
					seasonmare_horse: parseInt(horse_id),
					seasonmare_season: parseInt(season_id)
				})
				.primaryKeys()
			})

			return this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.where('seasonmarestallion_seasonmare').anyOf(seasonMareIds)
				.transform(new SeasonMareStallionTransformer())
			})
			.then(seasonMareStallions => {
				return seasonMareStallions.filter(sms => {
					return sms.seasonmarestallion_default === 1
				})
			})
        },
        loadSeasonStat: async function(season_id) {
			const params = {
				season_id: season_id
			}
        	const url = this.constructRoute(Constants.GYNECOLOGIE_STAT_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("GynecologieMixin::loadSeasonStat", url, params, false)
            .catch(error => {
                console.error("GynecologieMixin::loadSeasonStat => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
   //          let seasons = await this.$storage.db.t('season')
   //          .then(table => {
   //              return table.toCollection()
   //          })
   //          .then(col => {
   //              return col.transform(new SeasonTransformer())
   //          })

			// // Pour les statistiques il faut considérer une transplantation comme une saillie
			// const actes_type_saillie_ids = await this.$storage.db.t('horse_actes_type')
			// .then(table => {
			// 	return table.where('actestype_code')
			// 	.anyOf(this.actes_type_saillie.concat(this.actes_type_transplantation))
			// 	.primaryKeys()
			// })

			// const actes_type_collecte_ids = await this.$storage.db.t('horse_actes_type')
			// .then(table => {
			// 	return table.where('actestype_code')
			// 	.anyOf(this.actes_type_collecte)
			// 	.primaryKeys()
			// })

			// const actes_type_gestation_ids = await this.$storage.db.t('horse_actes_type')
			// .then(table => {
			// 	return table.where('actestype_code')
			// 	.anyOf(this.actes_type_gestation)
			// 	.primaryKeys()
			// })

   //          let seasons_res = []

			// // Pour chaque saison
   //          await Promise.all(seasons.map(async (season) => {
			// 	let full_mare = 0
   //              let empty_mare = 0
			// 	let waiting_mare = 0
				
			// 	// On récupère toutes les juments de cette saison
			// 	let season_mare = await this.$storage.db.t('season_mare')
   //              .then(table => {
   //                  return table.where({seasonmare_season: season.season_id})
   //              })
   //              .then(col => {
   //                  return col.transform(new SeasonMareTransformer())
			// 	})
				
   //              await Promise.all(season_mare.map(async (mare) => {

			// 		const mare_actes_type_ids = []
			// 			.concat(actes_type_saillie_ids)
			// 			.concat(actes_type_gestation_ids)
			// 			.concat(actes_type_collecte_ids)

			// 		const actes_mare = await this.$storage.db.t('horse_actes')
			// 		.then(table => {
			// 			// Actes validés dans la saison, pour la jument
			// 			return table.where({
			// 				actes_actesstatut: 1,
			// 				actes_horse: mare.seasonmare_horse
			// 			})
   //                      .filter(acte => {
			// 				return acte.actes_date <= season.season_end && acte.actes_date >= season.season_start
			// 					&& mare_actes_type_ids.includes(acte.actes_actestype)
			// 			})
			// 		})
			// 		.then(col => {
			// 			return col.transform(new ActeTransformer('gynecoJumenterie'))
			// 		})

			// 		// On récupére la dernière insémination en date (le résultat de dexie est en orderBy actes_date, et actes_id)
			// 		const indexOfInsem = actes_mare.findIndex(act => actes_type_saillie_ids.includes(act.actes_actestype))
			// 		const insemination = actes_mare[indexOfInsem]

			// 		const indexOfCollecte = actes_mare.findIndex(act => actes_type_collecte_ids.includes(act.actes_actestype))

			// 		// Collecte plus récente que la saillie -> Collectée
			// 		if(indexOfCollecte !== -1 && indexOfCollecte < indexOfInsem) {
			// 			return
			// 		}

			// 		let dgPlus = false
			// 		let dgNegativ = false
			// 		let hasSaillie = false

			// 		// Le premier constat négatif
			// 		const negativ_gesta = actes_mare.findIndex(act => 
			// 			act.reponse.reponse_formatted_data.includes(this.constatation_gestation_negative.find(code => 
			// 				act.reponse.reponse_formatted_data.includes(code)
			// 			))
			// 		)

			// 		// Si l'éventuel DG négatif a été inséré après la dernière insémination
			// 		if((negativ_gesta >= 0 && negativ_gesta < indexOfInsem) || negativ_gesta === -1) {
			// 			if(insemination) {
			// 				hasSaillie = true
			// 			}
			
			// 			// On récupère la dernière constatation de gestation
			// 			const gestation = actes_mare.find(act => actes_type_gestation_ids.includes(act.actes_actestype))
						
			// 			if(gestation) {						
			// 				this.constatation_gestation.forEach(code => {
			// 					// Si on a une réponse de diagnostic de gestation positif
			// 					if(gestation.reponse.reponse_formatted_data.includes(code)) {
			// 						dgPlus = true
			// 					}
			// 				})
			// 			}
			// 		}
			// 		else if(insemination) {
			// 			hasSaillie = true
			// 			dgNegativ = true
			// 		}

			// 		if(hasSaillie && dgNegativ) {
   //                      empty_mare++;
			// 		}
			// 		else if(hasSaillie && dgPlus) {
   //                      full_mare++;
			// 		}
			// 		else if(hasSaillie) {
   //                      waiting_mare++;
			// 		}
			// 	}))
				
   //              season.full_mare = full_mare
   //              season.empty_mare = empty_mare
   //              season.waiting_mare = waiting_mare

   //              seasons_res.push(season)
   //          }))

   //          return seasons_res
        },
        loadHorseFertilite: async function(horse_id) {
            let seasonmarestallion = await this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table.where({seasonmarestallion_horse: parseInt(horse_id)})
            })
            .then(col => {
                return col.transform(new SeasonMareStallionTransformer('withMare'))
            })

            let acte_typedg = await this.getActeTypeByCode("REPRO_DIAGNOSTIC_DE_GESTATION");
            let acte_typeco = await this.getActeTypeByCode("REPRO_CONSTATATION_DOVULATION");

			let seasons = {}
            let season_stat = {}
            await Common.asyncForEach(seasonmarestallion, async (seasonmare) => {
				if (!seasons[seasonmare.mare.seasonmare_season]) {
					seasons[seasonmare.mare.seasonmare_season] = await this.$storage.db.t('season')
					.then(table => {
						return table.get(seasonmare.mare.seasonmare_season)
					})
				}

                const mare_id = seasonmare.mare.seasonmare_horse
                const season = seasons[seasonmare.mare.seasonmare_season]

                if(!Object.prototype.hasOwnProperty.call(season_stat, season.season_id)) {
                    season_stat[season.season_id] = {
                        name: season.season_label,
                        monte: {}
                    }
                }
                let actes_mare_dg = await this.$storage.db.t('horse_actes')
                .then(table => {
                    return table.where({actes_actesstatut: 1, actes_horse: mare_id, actes_actestype: acte_typedg.actestype_id})
                    .filter(acte => (acte.actes_date <= season.season_end || acte.actes_date >= season.season_start))
                })
                .then(col => {
                    return col.transform(new ActeTransformer('gyneco'))
                })

                await Promise.all(actes_mare_dg.map(async (acte) => {
                    const temp_dg = await this.checkActeDG(acte, horse_id)
                    if(temp_dg) {
                        if(!Object.prototype.hasOwnProperty.call(season_stat[season.season_id].monte, temp_dg)) {
                            let actetype = await this.getActeTypeByCode(temp_dg)
                            season_stat[season.season_id].monte[temp_dg] = {
                                chal_fec: 0,
                                chal_expl: 0,
                                name: actetype.actestype_label
                            }
                        }
                        season_stat[season.season_id].monte[temp_dg].chal_fec++
                    }
                }))

                let actes_mare_co = await this.$storage.db.t('horse_actes')
                .then(table => {
                    return table.where({actes_actesstatut: 1, actes_horse: mare_id, actes_actestype: acte_typeco.actestype_id})
                    .filter(acte => (acte.actes_date <= season.season_end || acte.actes_date >= season.season_start))
                })
                .then(col => {
                    return col.transform(new ActeTransformer('gyneco'))
                })

                await Promise.all(actes_mare_co.map(async (acte) => {
                    const temp_co = await this.checkActeCO(acte, horse_id)
                    if(temp_co) {
                        if(!Object.prototype.hasOwnProperty.call(season_stat[season.season_id].monte, temp_co)) {
                            let actetype = await this.getActeTypeByCode(temp_co)
                            season_stat[season.season_id].monte[temp_co] = {
                                chal_fec: 0,
                                chal_expl: 0,
                                name: actetype.actestype_label
                            }
                        }
                        season_stat[season.season_id].monte[temp_co].chal_expl++
                    }
                }))
            })

            let season_res = []
            for(let stat in season_stat) {
                for(let monte in season_stat[stat].monte) {
                    let fertilite = (season_stat[stat].monte[monte].chal_fec / season_stat[stat].monte[monte].chal_expl) * 100
                    season_res.push({
                        season_label: season_stat[stat].name,
                        type_monte: season_stat[stat].monte[monte].name,
                        fertilite: fertilite.toFixed(2) + '%'
                    })
                }
            }

            return season_res
        },
        // vérifie qu'un acte de type DG est comptabilisable dans le taux de chaleurs fécondées
        // i.e. : précédé d'une saillie de l'étalon horse_id et d'une constatation d'ovulation mais pas de DG entre le CO et l'acte en param
        checkActeDG: async function(acte, horse_id) {
            if(!acte.reponse.reponse_formatted_data.includes('DG+') && !acte.reponse.reponse_formatted_data.includes('DG++')) {
                return false
            }
            let date_dg = acte.actes_date
            let mare = acte.actes_horse

            let acte_typeco = await this.getActeTypeByCode("REPRO_CONSTATATION_DOVULATION");
            let acte_co = await this.$storage.db.t('horse_actes')
            .then(table => {
                return table
                .where({actes_actesstatut: 1, actes_horse: parseInt(mare), actes_actestype: parseInt(acte_typeco.actestype_id)})
                .filter(acte => (Date.parseTz(acte.actes_date) <= Date.parseTz(date_dg)))
                .reverse()
                .sortBy('actes_date')
            })
            .then(async array => {
                return await ActeTransformer.process(array[0], 'gyneco')
            })
            if(!acte_co) {
                return false
            }

            let acte_typedg = await this.getActeTypeByCode("REPRO_DIAGNOSTIC_DE_GESTATION");
            let actes_dg = await this.$storage.db.t('horse_actes')
            .then(table => {
                return table
                .where({actes_actesstatut: 1, actes_horse: parseInt(mare), actes_actestype: parseInt(acte_typedg.actestype_id)})
                .filter(acte => (Date.parseTz(acte.actes_date) < Date.parseTz(date_dg) && Date.parseTz(acte.actes_date) >= Date.parseTz(acte_co.actes_date)))
            })
            .then(col => {
                return col.transform(new ActeTransformer('gyneco'))
            });
            
            if(actes_dg.length > 0) {
                return false
            }

            let date_co_before = new Date(acte_co.actes_date.getTime())
            date_co_before.setDate(date_co_before.getDate() - 3)
            let date_co_after = new Date(acte_co.actes_date.getTime())
            date_co_after.setDate(date_co_after.getDate() + 3)

            let actes_mare = await this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where({actes_actesstatut: 1, actes_horse: parseInt(mare)})
                .filter(acte => (Date.parseTz(acte.actes_date) <= Date.parseTz(date_dg) && (Date.parseTz(acte.actes_date) >= Date.parseTz(date_co_before) && Date.parseTz(acte.actes_date) <= Date.parseTz(date_co_after))))
                .sortBy('actes_date')
            })
            .then(async array => {
                return await ActeTransformer.process(array, 'gyneco')
            })

            let isValid = false;
            actes_mare.some(acte_mare => {
                if(this.actes_type_saillie.includes(acte_mare.actetype.actestype_code) && acte_mare.reponse.reponse_hidden_data) {
                    let valid = acte_mare.reponse.reponse_hidden_data.some(reponse => {
                        if(reponse && reponse.type === "stallion" && reponse.id == horse_id) {

                            isValid = acte_mare.actetype.actestype_code;
                            return true
                        }
                    })

                    if(valid) {
                        return true
                    }
                }
            })
            return isValid
        },
        // vérifie qu'un acte de type CO est comptabilisable dans le taux de chaleurs exploitées
        // i.e. : une ou plusieurs saillie de l'étalon horse_id dans les 72h avant ou après 
        checkActeCO: async function(acte, horse_id) {
            let date_co_before = new Date(acte.actes_date.getTime())
            date_co_before.setDate(date_co_before.getDate() - 3)

            let date_co_after = new Date(acte.actes_date.getTime())
            date_co_after.setDate(date_co_after.getDate() + 3)
            let mare = acte.actes_horse

            let actes_mare = await this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where({actes_actesstatut: 1, actes_horse: parseInt(mare)})
                .filter(acte => (Date.parseTz(acte.actes_date) >= Date.parseTz(date_co_before) && Date.parseTz(acte.actes_date) <= Date.parseTz(date_co_after)))
                .sortBy('actes_date')
            })
            .then(async array => {
                return await ActeTransformer.process(array, 'gyneco')
            })

            let isValid = false;
            actes_mare.some(acte_mare => {
                if(this.actes_type_saillie.includes(acte_mare.actetype.actestype_code) && acte_mare.reponse.reponse_hidden_data) {

                    let valid = acte_mare.reponse.reponse_hidden_data.some(reponse => {
                        if(reponse && reponse.type === "stallion" && reponse.id == horse_id) {
                            isValid = acte_mare.actetype.actestype_code;
                            return true
                        }
                    })

                    if(valid) {
                        return true
                    }
                }
            })

            return isValid
        },

		loadStatFertilite: async function(season_id) {

			const url = this.constructRoute(Constants.SEASON_FERTILITE_URL, { season_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("GynecologieMixin::loadStatFertilite", url)
			.then(res => {
				return res.retour
			})
			.catch(error => {
				console.error("GynecologieMixin::loadStatFertilite => ERROR", error)
				return []
			})
		},

        saveDateTerme: async function(horse_id, date, sms_id = null) {
        	if(sms_id) {
        		return this.$storage.db.t('season_mare_stallion')
                    .then(table => {
                        return table.update(parseInt(sms_id), {
                            seasonmarestallion_dt: date,
                        })
                    })
        	}

            const season_mare = await this.loadSeasonMareHorse(horse_id)
            if(season_mare.length === 0){
                return false  
            }

            season_mare.forEach(mare => {
                if(Date.parseTz(mare.season.season_start) < Date.parseTz(date) && Date.parseTz(mare.season.season_end) > Date.parseTz(date)) {
                    const stallion = mare.seasonmarestallion.filter(stallion => stallion.seasonmarestallion_default)[0]
					if (!stallion) {
						return
					}

                    return this.$storage.db.t('season_mare_stallion')
                        .then(table => {
                            return table.update(parseInt(stallion.seasonmarestallion_id), {
                                seasonmarestallion_dt: date,
                            })
                        })
                }
            })

        },

		recalculDateTerme: async function(season_mare_stallion, acte_dg = null){
			if(!season_mare_stallion){
				return
			}
			
			if(acte_dg && acte_dg.reponse.dg && ["DG-", "Avortée", "Coulée"].includes(acte_dg.reponse.dg)) {
				return await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table.update(parseInt(season_mare_stallion.seasonmarestallion_id), {
						seasonmarestallion_dt: null,
					})
				})
			}

			let dt = null;
			let last_insemin = null;
			if(season_mare_stallion.seasonmarestallion_ds) {
				last_insemin = await this.getActeFromId(season_mare_stallion.seasonmarestallion_ds)
			}

			let is_transplantation = false;
			if(season_mare_stallion.seasonmarestallion_transplantation){
				const transplantation = await this.getActeFromId(season_mare_stallion.seasonmarestallion_transplantation)
				if(season_mare_stallion.seasonmarestallion_transplantation && (!season_mare_stallion.seasonmarestallion_ds || transplantation.actes_date > last_insemin.actes_date)) {
					last_insemin = transplantation;
					is_transplantation = true;    
				}
			}

			//2022-01-18 // Bertrand pour GFE : si on a un dg+ il faut prendre cette info pour calculer la date de terme si pas de saillie
			if(last_insemin === null && season_mare_stallion.seasonmarestallion_dg && season_mare_stallion.seasonmarestallion_status.indexOf("DG+") > -1) {
				last_insemin = await this.getActeFromId(season_mare_stallion.seasonmarestallion_dg)
			}

			if(last_insemin && last_insemin.actes_date) {
				dt = new Date(last_insemin.actes_date.getTime());

				if(is_transplantation) {
					dt = dt.setDate(dt.getDate() + 330);
				}
				else {
					dt = dt.setDate(dt.getDate() + 338);
				}
			}
			else if(acte_dg) {
				dt = new Date(acte_dg.actes_date.getTime());
				dt = dt.setDate(acte_dg.actes_date.getDate() + 338);
			}

			this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.update(parseInt(season_mare_stallion.seasonmarestallion_id), {
					seasonmarestallion_dt: new Date(dt).toISOString(),
				})
			})
		},

        loadMareStatus: async function() {
            return this.$storage.db.t('mare_status')
            .then(table => {
                return table.toArray()
            })
        },

        saveStatutSanitary: async function(seasonmare_id, marestatus_id, sanitary = null) {
            if(sanitary !== null) {
                return this.$storage.db.t('season_mare')
                .then(table => {
                    return table.update(parseInt(seasonmare_id), {
                        seasonmare_status: parseInt(marestatus_id),
                        seasonmare_sanitary: sanitary
                    })
                })
            }
            return this.$storage.db.t('season_mare')
                .then(table => {
                    return table.update(parseInt(seasonmare_id), {
                        seasonmare_status: parseInt(marestatus_id)
                    })
                })
        },

        saveSanitary: async function(seasonmare_id, sanitary) {
            return this.$storage.db.t('season_mare')
                .then(table => {
                    return table.update(parseInt(seasonmare_id), {
                        seasonmare_sanitary: sanitary,
                    })
                })
        },

        deleteSeasonMare: async function(seasonmare_id) {
            return this.$storage.db.t('season_mare')
                .then(table => {
                    return table.where('seasonmare_id')
                    .equals(parseInt(seasonmare_id))
                    .invalid()
            })
        },

        deleteSeasonById: async function(season_id) {
            return this.$storage.db.t('season')
            .then(table => {
                return table.where({season_id: parseInt(season_id)}).invalid()
            })
        },
        deleteSeasonMareStallion: async function(seasonmarestallion_id, check = false) {
        	if(check) {
        		const can_delete = await this.checkSeasonMareStallion(seasonmarestallion_id)
        		if(!can_delete) {
        			return false
        		}
        	}
            return this.$storage.db.t('season_mare_stallion')
                    .then(table => {
                        return table.where('seasonmarestallion_id')
                        .equals(parseInt(seasonmarestallion_id))
                        .invalid()
                })
        },

        // vérifie qu'un couple peut être supprimé
        // c-a-d qu'il n'a pas d'acte ou de contrat
        checkSeasonMareStallion: async function(seasonmarestallion_id) {
        	const sms = await this.loadSeasonMareStallion(seasonmarestallion_id)
        	const season = sms.mare.season
        	const mare_id = sms.mare.horse.horse_id

        	// je récupère les actes de la jument sur la saison
        	const actes_mare = await this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where({actes_horse: parseInt(mare_id)})
                .filter(acte => Date.parseTz(acte.actes_date) <= Date.parseTz(season.season_end) && (Date.parseTz(acte.actes_date) >= Date.parseTz(season.season_start)))
                .sortBy('actes_date')
            })
            .then(async array => {
                return await ActeTransformer.process(array, 'withReponseNotFormatted')
            })

            // je filtre sur les actes ayant une réponse de type season_mare_stallion liée à mon couple
            const reponses_sms = actes_mare.filter(acte => acte.reponse.filter(reponse => reponse.reponse_data_hidden && reponse.reponse_data_hidden.type == 'season_mare_stallion' && reponse.reponse_data_hidden.id == seasonmarestallion_id).length > 0)

            // si j'ai au moins 1 acte lié à mon couple, je ne peux pas supprimer donc je retourne false
            if(reponses_sms.length > 0) {
            	return false
            }

            if(!this.$store.state.userAccess.hasSaillieAccess) {
            	return true
            }

        	// je récupère les contrats de la jument
            const contracts_mare = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.where({avenant_horse: parseInt(mare_id)})
            })
            .then(col => {
                return col.transform(new ContractAvenantTransformer())
            })

            // je filtre sur les contrats de la bonne saison avec le bon étalon
            const contracts_sms = contracts_mare.filter(avenant => avenant.contract && avenant.contract.config && avenant.contract.config.contractconfig_season == season.season_id && avenant.contract.config.contractconfig_horse == sms.seasonmarestallion_horse)
            
            // si j'ai au moins 1 contrat lié à mon couple, je ne peux pas supprimer donc je retourne false
            if(contracts_sms.length > 0) {
            	return false
            }

        	return true
        },

        calculEtatJument: async function(seasonmare_id) {
            let actes_type_saillie_ids = await this.$storage.db.t('horse_actes_type')
                    .then(table => {
                        return table.filter(type => this.actes_type_saillie.includes(type.actestype_code))
                        .toArray()
                    })
            actes_type_saillie_ids = actes_type_saillie_ids.map(type => type.actestype_id)

            let actes_type_gestation_ids = await this.$storage.db.t('horse_actes_type')
                    .then(table => {
                        return table.filter(type => this.actes_type_gestation.includes(type.actestype_code))
                        .toArray()
                    })
            actes_type_gestation_ids = actes_type_gestation_ids.map(type => type.actestype_id)

            const seasonmare = await this.$storage.db.t('season_mare')
                .then(table => {
                    return table.get(parseInt(seasonmare_id))
                })
                .then(season => {
                    return SeasonMareTransformer.process(season, 'seasonMareOnlyHorseSeason')
                })

            let mare_id = seasonmare.horse.horse_id
			let season = await this.loadSeasonById(seasonmare.seasonmare_season)

            const actes_mare = await this.$storage.db.t('horse_actes')
            .then(table => {
                // Actes validés dans la saison, pour la jument
                return table.where({actes_actesstatut: 1, actes_horse: mare_id})
                .filter(acte => (new Date(acte.actes_date) <= new Date(season.season_end) && new Date(acte.actes_date) >= new Date(season.season_start))
                    && (actes_type_saillie_ids.includes(acte.actes_actestype) || actes_type_gestation_ids.includes(acte.actes_actestype)))
                .sortBy('actes_date')
            })
            .then(async array => {
                return await ActeTransformer.process(array.reverse(), 'gynecoJumenterie')
            })
            const actes_mare_reverse = [...actes_mare].reverse()

            let hasdg = false
            let dgPlus = false
            let hasSaillie = false
            let dps, dds, dt

            // On récupére la dernière insémination en date (le résultat de dexie est en orderBy actes_date, et actes_id)
            const insemination_last = actes_mare.find(act => actes_type_saillie_ids.includes(act.actes_actestype))
            
            const indexOfInsem = actes_mare.indexOf(insemination_last)

            if(insemination_last) {
                hasSaillie = true
            }

            // On récupère la dernière constatation de gestation
            const gestation = actes_mare.find(act => actes_type_gestation_ids.includes(act.actes_actestype))

            if(gestation && actes_mare.indexOf(gestation) < indexOfInsem) {
                hasdg = true
                
                this.constatation_gestation.forEach(code => {
                    // Si on a une réponse de diagnostic de gestation positif
                    if(gestation.reponse.reponse_formatted_data.includes(code)) {
                        dgPlus = true
                    }
                })
            }
                
            if(dds && dgPlus) {
                dt = new Date(dds)
                dt.setDate(dt.getDate() + 338)
            }

            if(dgPlus) {
                return "full"
            } else if(hasSaillie && hasdg) {
                return "empty"
            } else if(hasSaillie) {
                return "waiting"
            } else {
                return "empty"
            }
        },

        getSeasonMareStallionDefaultBySeasonMare: async function(seasonmare_id) {
            return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table
                .where({seasonmarestallion_seasonmare: seasonmare_id, seasonmarestallion_default: 1})
                .first()
            })
        },

        getSeasonMareStallionBySeasonMareAndStallion: async function(seasonmare_id, stallion_id, porteuse_null = false) {
        	if(porteuse_null) {
        		 return this.$storage.db.t('season_mare_stallion')
	            .then(table => {
	                return table
	                .where({
	                	seasonmarestallion_seasonmare: seasonmare_id, 
	                	seasonmarestallion_horse: stallion_id
	                })
	                .filter(sms => sms.seasonmarestallion_porteuse == null)
	                .first()
	            })
        	}
            return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table
                .where({
                	seasonmarestallion_seasonmare: seasonmare_id, 
                	seasonmarestallion_horse: stallion_id
                })
                .first()
            })
        },

        getSeasonMareStallionBySeasonMareStallionAndPorteuse: async function(seasonmare_id, stallion_id, porteuse) {
            return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table
                .where({
                	seasonmarestallion_seasonmare: seasonmare_id, 
                	seasonmarestallion_horse: stallion_id,
                	seasonmarestallion_porteuse: porteuse
                })
                .first()
            })
        },

		async generatePdfSuiviGyneco(horse_id, season_id, stallion_id) {
			const params = {
				horse_id: horse_id,
				season_id: season_id,
				stallion_id: stallion_id
			}
			const url = Constants.HORSE_SUIVI_GYNECO_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("HorseMixin::generatePdfSuiviGyneco", url, params, false)
			.catch(error => {
				console.error("HorseMixin::generatePdfSuiviGyneco => ERROR", error)
				return null
			})
			
			if(result) {
				Common.base64ToPdf(result, "suivi.pdf")
			}
			else {
				return result
			}
		},

		async getSeasonMareStallionFromPreviousSeason(season, horse_id) {
			const previous_season = await this.$storage.db.t('season')
	            .then(table => {
	                return table.toCollection()
	                .filter(s => (s.season_end <= season.begin))
	            })
	            .then(col => {
					return col.first()
				})

			if(!previous_season) return null

			const season_mare = await this.$storage.db.t('season_mare')
	            .then(table => {
	                return table
					.where({
						seasonmare_season: previous_season.season_id,
						seasonmare_horse: horse_id
					})
					.first()
				})

			if(!season_mare) return null

			const season_mare_stallion = await this.$storage.db.t('season_mare_stallion')
	            .then(table => {
					return table.where({
						seasonmarestallion_seasonmare: season_mare.seasonmare_id
					})
					.and((sms) => (sms.seasonmarestallion_porteuse === null))
					.or('seasonmarestallion_porteuse').equals(season_mare.seasonmare_id)
					.and((sms) => (sms.seasonmarestallion_default == 1))
					.first()
	            })
				.then(sms => {
					return SeasonMareStallionTransformer.process(sms)
				})

			return season_mare_stallion;
		},

		async getAllSeasonMareStallionFromPreviousSeason(season_id) {
			const season_mares = await this.$storage.db.t('season_mare')
	            .then(table => {
	                return table
					.where({
						seasonmare_season: season_id,
					}).toArray()
				})

			if(!season_mares) return null

			const seasonmares_ids = season_mares.map(sm => sm.seasonmare_id)

			const season_mares_stallions = await this.$storage.db.t('season_mare_stallion')
	            .then(table => {
					return table.where('seasonmarestallion_seasonmare').anyOf(seasonmares_ids)
					.and((sms) => (sms.seasonmarestallion_porteuse === null))
					.or('seasonmarestallion_porteuse').anyOf(seasonmares_ids)
					.and((sms) => (sms.seasonmarestallion_default == 1)).toArray()
	            })

			return season_mares_stallions;
		},

		getActualSeason() {
			let now = new Date()
			return this.$storage.db.t('season')
	            .then(table => {
	                return table.toCollection()
	                .filter(s => (s.season_start <= now) && (s.season_end >= now))
	            })
	            .then(col => {
                	return col.transform(new SeasonTransformer())
				})
		},
		async getPreviousSeason() {
			let actual_season = await this.getActualSeason()
			if(!actual_season || (actual_season && actual_season.length == 0)) return null

			return this.$storage.db.t('season')
			.then(table => {
				return table.toCollection()
				.filter(s => (s.season_end <= actual_season[0].season_start))
			})
			.then(col => {
				return col.transform(new SeasonTransformer())
			})
			.then(res => {
				return _orderBy(res, ['season_end'], ['desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
		},

		async getPreviousSeasonFromSeason(season_id) {
			let season = await this.$storage.db.t('season')
            .then(table => {
                return table.get(parseInt(season_id))
            })
			
			if(!season) return null

			return this.$storage.db.t('season')
			.then(table => {
				return table.toCollection()
				.filter(s => (s.season_end <= season.season_start))
			})
			.then(col => {
				return col.transform(new SeasonTransformer())
			})
			.then(res => {
				return _orderBy(res, ['season_end'], ['desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
		},

		getNumDps: async function(mare_id, stallion_id, season_id) {
			let season_mare = await this.loadSeasonMareStallionBySeasonHorse(mare_id, season_id)
				.then(res => {
					if(res.length > 0) return res[0]
					return null
				})

			if(!season_mare) return null

			return this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table.where({
						seasonmarestallion_seasonmare: parseInt(season_mare.seasonmare_id),
						seasonmarestallion_horse: parseInt(stallion_id)
					})
				})
				.then(col => {
					return col.transform(new SeasonMareStallionTransformer())
				})
				.then(res => {
					if(res.length > 0) return res[0].seasonmarestallion_num_dps
					return null
				})
		},

		updateMareStatusDead: async function(mare_id, date) {
			let season = await this.getCurrentSeason(date)
			if(!season) return false

			let previous_season = await this.getPreviousSeasonFromSeason(season[0].season_id)

			let season_mare = await this.$storage.db.t('season_mare')
				.then(table => {
				return table
					.where({
						seasonmare_horse: parseInt(mare_id),
						seasonmare_season: season[0].season_id
					})
	            })
				.then(col => {
					return col.transform(new SeasonMareTransformer())
				})

			if(previous_season) {
				const season_mare_previous = await this.$storage.db.t('season_mare')
					.then(table => {
					return table
						.where({
							seasonmare_horse: parseInt(mare_id),
							seasonmare_season: previous_season.season_id
						})
		            })
					.then(col => {
						return col.transform(new SeasonMareTransformer())
					})

				season_mare = season_mare.concat(season_mare_previous)
			}

	        if(season_mare.length == 0) return false

	        const sm_ids = season_mare.map(sm => sm.seasonmare_id)

	    	// si jument morte sans porteuse et sans poulinage
			await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_seasonmare')
					.anyOf(sm_ids)
					.and(sm => sm.seasonmarestallion_poulinage == null && sm.seasonmarestallion_porteuse == null)
					.modify({
						'seasonmarestallion_status': `Morte le ${this.formatStatusDate(date)}`
					})
				})

	    	// si porteuse morte et sans poulinage
			await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_porteuse')
					.anyOf(sm_ids)
					.and(sm => sm.seasonmarestallion_poulinage == null)
					.modify({
						'seasonmarestallion_status': `Morte le ${this.formatStatusDate(date)}`
					})
				})

			
	    	// si jument morte sans porteuse et avec poulinage
			await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_seasonmare')
					.anyOf(sm_ids)
					.and(sm => sm.seasonmarestallion_poulinage != null)
					.modify({
						'seasonmarestallion_status': `PV, Morte le ${this.formatStatusDate(date)}`
					})
				})

	    	// si porteuse morte et avec poulinage
			await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_porteuse')
					.anyOf(sm_ids)
					.and(sm => sm.seasonmarestallion_poulinage != null)
					.modify({
						'seasonmarestallion_status': `PV, Morte le ${this.formatStatusDate(date)}`
					})
				})

			// reindexe les season_mare_stallion en tant que jument
			let season_mare_stallions = await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_seasonmare')
					.anyOf(sm_ids)
					.toArray()
				})

			let sms_ids = season_mare_stallions.map(sms => sms.seasonmarestallion_id)
			sms_ids.forEach(id => {
				SeasonMareStallionCleaner.inst().onMutation(id, ['season_mare_stallion'])
			});

			// reindexe les season_mare_stallion en tant que porteuse
			season_mare_stallions = await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_porteuse')
					.anyOf(sm_ids)
					.toArray()
				})

			sms_ids = season_mare_stallions.map(sms => sms.seasonmarestallion_id)
			sms_ids.forEach(id => {
				SeasonMareStallionCleaner.inst().onMutation(id, ['season_mare_stallion'])
			});
		},

		addNewCross: async function (season_id, mare, stallion) {
			const season_mare = await this.$storage.db.t('season_mare')
				.then(table => {
					return table
					.where({
						seasonmare_horse: mare.horse_id,
						seasonmare_season: season_id
					})
				})
				.then(col => {
					return col.transform(new SeasonMareTransformer())
				})

			let seasonmare_id = null
			if(season_mare.length == 0) {
				seasonmare_id = await this.addHorseSeasonMare(season_id, mare.horse_id, "")
			}
			else {
				seasonmare_id = season_mare[0].seasonmare_id
			}

			const result = await this.addSeasonMareStallion(seasonmare_id, stallion.horse_id, null, true)

			return result
		},

		getMaresWithoutCross: async function (season_id) {
			let horses = await this.$storage.db.t('horse')
				.then(table => {
					return table.where({
						horse_sexe: "F",
						horse_inactive: 0
					})
				})
				.then(col => {
					return col.transform(new HorseTransformer('light'))
				})
				.then(horses => {
					return horses.filter(horse => horse.horse_age >= 2)
				})


			let horses_without_cross = []
			await Common.asyncForEach(horses, async (horse) => {
				let season_mare = await this.$storage.db.t('season_mare')
					.then(table => {
						return table
						.where({
							seasonmare_horse: horse.horse_id,
							seasonmare_season: season_id
						})
					})
					.then(col => {
						return col.transform(new SeasonMareTransformer('withSeasonMareStallions'))
					})

				if(season_mare.length > 0) {
					if(season_mare[0].seasonmarestallion.length == 0) {
						horses_without_cross.push(horse)
					}
				}
				else {
					horses_without_cross.push(horse)
				}
			})

			return horses_without_cross
		},

		getMaresInSeasonWithoutStallion: async function (season_id) {
			let horses = await this.$storage.db.t('horse')
			.then(table => {
				return table.where({
					horse_sexe: "F",
					horse_inactive: 0
				})
			})
			.then(col => {
				return col.transform(new HorseTransformer('withTiers'))
			})
			.then(horses => {
				return horses.filter(horse => horse.horse_age >= 2)
			})


			let horses_without_stallion = []
			await Common.asyncForEach(horses, async (horse) => {
				let season_mare = await this.$storage.db.t('season_mare')
					.then(table => {
						return table
						.where({
							seasonmare_horse: horse.horse_id,
							seasonmare_season: season_id
						})
					})
					.then(col => {
						return col.transform(new SeasonMareTransformer('withSeasonMareStallions'))
					})

				if(season_mare.length > 0) {
					if(season_mare[0].seasonmarestallion.length == 0) {
						horses_without_stallion.push(horse)
					}
				}
			})

			return horses_without_stallion
		},

		// Boucle pour récupérer le season_mare_stallion le plus récent
		getMoreRecentSeasonMareStallion(season_mare_stallions, sms) {
			season_mare_stallions.forEach(s => {
				if(s.seasonmarestallion_porteuse) {
					sms.push(s)
				} 
				else {
					let sm = sms.filter(s2 => s2.seasonmarestallion_seasonmare == s.seasonmarestallion_seasonmare && !s2.seasonmarestallion_porteuse)
					if(sm.length > 0) {
						let status1 = s.seasonmarestallion_status
						let status2 = sm[0].seasonmarestallion_status
						let index = sms.indexOf(sm[0])
						if(status1 != "Non saillie") {
							if(status2 == "Non saillie") {
								sms[index] = s
							}
							else {
								let match1 = status1.match(/[0-9]*\/[0-9]*\/[0-9]*/gm)
								let match2 = status2.match(/[0-9]*\/[0-9]*\/[0-9]*/gm)

								match1 = match1 ? match1[0] : ''
								match2 = match2 ? match2[0] : ''
								
								let split1 = match1.split('/')
								let split2 = match2.split('/')

								let date1 = Date.parse(split1[1]+"/"+split1[0]+"/20"+split1[2])
								let date2 = Date.parse(split2[1]+"/"+split2[0]+"/20"+split2[2])

								if(date1 > date2) {
									sms[index] = s
								}
							}
						}
					}
					else {
						sms.push(s)
					}
				}
			})

			return sms
		},

		getSeasonMareStallionById: async function(seasonmarestallion_id) {
            return this.$storage.db.t('season_mare_stallion')
            .then(table => {
                return table.where({
					seasonmarestallion_id: parseInt(seasonmarestallion_id)
				}).first()
            })
        },

        getSaillieByMareBetweenDate: async function(mare_id, begin, end) {
        	const actes_type_saillie_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(this.actes_type_saillie)
				.primaryKeys()
			})

			return this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where({ actes_horse: mare_id})
                .filter(acte => (new Date(acte.actes_date) <= end && new Date(acte.actes_date) >= begin)
                    && actes_type_saillie_ids.includes(acte.actes_actestype)).toArray()
            })
        },

        getStatSailliesByStallionAndSeason: async function(season_id, stallion_id) {
        	const url = this.constructRoute(Constants.STAT_SEASON_STALLION_URL, { season_id, stallion_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("GynecologieMixin::getStatSailliesByStallionAndSeason", url)
			.then(res => {
				return res.retour
			})
			.catch(error => {
				console.error("GynecologieMixin::getStatSailliesByStallionAndSeason => ERROR", error)
				return []
			})
        },

        addContractFromHorseBis: async function(horse_id){
            let contract_id = await this.$storage.db.t('contract')
            .then(table => {
                return table.add({
                    contract_id: Common.getNegativeId(),
                    contract_num: null,
                    contract_note: null,
                    contract_contractconfig: null,
                    contract_valide: 1
                })
            })

            const type = await this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.where({avenanttype_type: 'initial'}).first()
            })

            const status = await this.$storage.db.t('contract_avenant_status')
            .then(table => {
                return table.where({avenantstatus_type: 'brouillon'}).first()
            })

            //J'ajoute l'avenant
            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add({
                    avenant_id: Common.getNegativeId(),
                    avenant_num: 1,
                    avenant_contract: contract_id,
                    avenant_type: type.avenanttype_id,
                    avenant_free: 0,
                    avenant_comment: null,
                    avenant_typemonte: null,
                    avenant_justification: null,
                    avenant_status: status.avenantstatus_id,
                    avenant_horse: parseInt(horse_id),
                    avenant_valide: 1
                })
            })

            return contract_id
        },

        addContractFromStallionBis: async function(){
            let contract_id = await this.$storage.db.t('contract')
            .then(table => {
                return table.add({
                    contract_id: Common.getNegativeId(),
                    contract_num: null,
                    contract_note: null,
                    contract_contractconfig: null,
                    contract_valide: 1
                })
            })

            const type = await this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.where({avenanttype_type: 'initial'}).first()
            })

            const status = await this.$storage.db.t('contract_avenant_status')
            .then(table => {
                return table.where({avenantstatus_type: 'brouillon'}).first()
            })

            //J'ajoute l'avenant
            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add({
                    avenant_id: Common.getNegativeId(),
                    avenant_num: 1,
                    avenant_contract: contract_id,
                    avenant_type: type.avenanttype_id,
                    avenant_free: 0,
                    avenant_typemonte: null,
                    avenant_comment: null,
                    avenant_justification: null,
                    avenant_status: status.avenantstatus_id,
                    avenant_horse: null,
                    avenant_valide: 1
                })
            })

            return contract_id
        },

        archiveCouple: async function(sms) {
        	const sms_id = sms.map(s => s.seasonmarestallion_id)

        	await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_id')
					.anyOf(sms_id)
					.modify({
						'seasonmarestallion_archive': 1
					})
				})
        },

        archiveOrUnarchiveCouple: async function(sms) {
        	const sms_id = sms.map(s => s.seasonmarestallion_id)
        	
        	await this.$storage.db.t('season_mare_stallion')
				.then(table => {
					return table
					.where('seasonmarestallion_id')
					.anyOf(sms_id)
					.modify({
						'seasonmarestallion_archive': !sms[0].seasonmarestallion_archive,
						'seasonmarestallion_default': false
					})
				})
        },

		getContractOnlineBis: async function(contract_id) {
            const url = this.constructRoute(Constants.CONTRACT_GET_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::getContractOnline", url)
			.catch(error => {
				console.error("HorseMixin::getContractOnline => ERROR", error)
				return null
			})

			if(response) return response.retour
            return null
        },

		addContractOnlineBis: async function(){
            const url = Constants.CONTRACT_ADD_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::addContractOnlineBis", url)
			.catch(error => {
				console.error("ContractMixin::addContractOnlineBis => ERROR", error)
				return null
			})

            if(response) {
                return response.retour
            }
            return null
        },

        addContractOnlineFromHorseBis: async function(horse_id){
            const url = this.constructRoute(Constants.CONTRACT_ADD_FROM_HORSE_URL, {horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::addContractOnlineFromHorseBis", url)
			.catch(error => {
				console.error("ContractMixin::addContractOnlineFromHorseBis => ERROR", error)
				return null
			})

            if(response) {
                return response.retour
            }
            return null
        },

		getInfoSailliesWs: async function(horse_id, season_id) {
        	const url = this.constructRoute(Constants.INFO_SAILLIES_SEASON_WS_URL, { horse_id, season_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("GynecologieMixin::getInfoSailliesWs", url)
			.then(res => {
				return res.retour
			})
			.catch(error => {
				console.error("GynecologieMixin::getInfoSailliesWs => ERROR", error)
				return []
			})
        },

		AddInfoSailliesWs: async function(horse_id, data) {
        	const url = this.constructRoute(Constants.INFO_SAILLIES_WS_URL, { horse_id }) + '?licence_key='+Constants.USER_LICENCE_KEY

			const infos = {
				"data": data
			}

			const response = await this.$request.request_post_api("GynecologieMixin::AddInfoSailliesWs", url, infos, false)
			.catch(e => {
				console.error("GynecologieMixin::AddInfoSailliesWs", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
        },

		sendMailDG: async function(model_id, seasonmarestallion_ids, expeditor, recipient) {
			let params = {
				model_id: model_id,
				seasonmarestallion_ids: seasonmarestallion_ids,
				expeditor: expeditor,
				recipient: recipient
			}

			const url = Constants.SEND_DG_MAIL + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("GynecologieMixin::sendMailDG", url, params, false)
			.catch(error => {
				console.log(error)
				console.error("GynecologieMixin::sendMailDG => ERROR", error)
				return 'error'
			})

			return result
		}
    }
}

export default GynecologieMixin
