<template>
    <b-modal :id="uniqueId" :ref="uniqueId" @hidden="handleClose">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('syndication.attribuer_parts') }}</h2>
        </template>
        <template slot="default">
            <b-form>
                <div class="form-group">
                    <div v-if="actual_part.tiers.length > 0" class="form-group">
                        <label>{{ $t('syndication.porteurs_actuels') }}</label>
                        <div class="mb-3" v-for="(tier, idx) in actual_part.tiers" :key="'tier_percent_'+idx">
                            <b-input-group :prepend="tier.tiers_rs" append="%">
                                <b-form-input v-model="actual_part.tiers[idx].percentage" readonly></b-form-input>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                
                <div class="form-group">
                    <label>{{ $t('syndication.date_cession') }}</label>
                    <e-datepicker v-model="part.cession_date" required />
                </div>

                <div class="form-group">
                    <label>{{ $t('syndication.ajouter_porteurs') }}</label>
                    <e-select
                        v-model="part.tiers"
                        track-by="tiers_id"
                        label="tiers_rs"
                        :placeholder="$t('syndication.selectionner_tiers_porteurs')"
                        :selectedLabel="$t('global.selected_label')"
                        :options="tiers"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                        :multiple="true"
                    />
                </div>

                <div v-if="part.tiers.length > 0" class="form-group">
                    <label>{{ $t('syndication.repartition_porteurs') }}</label>
                    <div class="mb-3" v-for="(tier, idx) in part.tiers" :key="'tier_percent_'+idx">
                        <b-input-group :prepend="tier.tiers_rs" append="%">
                            <b-form-input v-model="part.tiers[idx].percentage" type="number" step="0.01" min="0.01" max="100"></b-form-input>
                        </b-input-group>
                    </div>
                </div>
            </b-form>
        </template>

        <template slot="modal-footer" class="justify-content-center">
            <b-button variant="primary" @click="checkForm()" :disabled="!formComplete">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                {{ $t('global.attribuer') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Gynecologie from "@/mixins/Gynecologie.js"
    import Syndic from "@/mixins/Syndic.js"
    import Tiers from "@/mixins/Tiers.js"

	export default {
        name: "AssignPartModal",
        mixins: [Gynecologie, Syndic, Tiers],
        props: {
            submitForm: { type: Function, default: () => false },
			syndic_invoice_types: { type: Array, default: () => [] }
        },
        data() {
            return {
                tiers: [],
                loading: true,
                processing: false,
                temp_part: null,

                actual_part: {   // actual part configuration
                    id: 0,
                    tiers: []
                },

                part: {         // form part configuration
                    tiers: [],
                    cession_date: new Date()
                }
            }
        },
        computed: {
            uniqueId() {
                return 'modal-edit-part-'+this._uid
            },
            formComplete() {
                return this.part.tiers.length > 0
                    && !this.hasInvalidPercentage
            },
            hasInvalidPercentage() {
                let result = false
                let total = 0

                this.part.tiers.forEach(tier => {
                    if(!tier.percentage || tier.percentage < 0 || tier.percentage > 100) {
                        result = true
                        return
                    }

                    total += parseFloat(tier.percentage)
                })

                return result || total > 100
            }
        },
		watch: {
			'syndic_invoice_types' (val) {
				this.part.invoice_type = val[0]
			}
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                let tab_promises = []
				this.part.invoice_type = this.syndic_invoice_types.length > 0 ? this.syndic_invoice_types[0] : null
                
                tab_promises.push(new Promise((rsv, _) => {
                    this.loadTiers()
                        .then(res => {
                            res.forEach((tier, idx) => {
                                res[idx].percentage = 0
                            })
                            this.tiers = res
                        })
                        .finally(rsv())
                }))

                await Promise.all(tab_promises)
                this.loading = false
            },

            checkForm() {
                if(this.formComplete) {
                    this.submitForm({
                        syndicpart_id: this.actual_part.id,
                        tiers: this.part.tiers.map(tier => { 
                            return { 'id': tier.tiers_id, 'percentage': tier.percentage }
                        }),
                        cession_date: this.part.cession_date
                    })
                }
            },

            openModal(part) {
                // Pour ne pas casser la réactivité, on cherche à retrouver les tiers à partir des ids de la part, dans tous les tiers récupérés
                const tiers_ids = part.syndicpart_tiers.map(tierpart => tierpart.tierpart_tier.tiers_id)
                const tiers = this.tiers.filter(tier => tiers_ids.indexOf(tier.tiers_id) > -1)

                // On reprend les pourcentages
                const tiers_percentage = part.syndicpart_tiers.map(tierpart => {
                    return {
                        id: tierpart.tierpart_tier.tiers_id,
                        percentage: tierpart.tierpart_percentage
                    }
                })
                tiers.forEach(tier => {
                    tier.percentage = tiers_percentage.find(t => t.id == tier.tiers_id).percentage
                })

                // On set le formulaire
                this.actual_part.tiers = tiers
                this.actual_part.id    = part.syndicpart_id
                this.part.tiers        = []
                this.$bvModal.show(this.uniqueId)
            },

            closeModal() {
                this.$bvModal.hide(this.uniqueId)
            },

            handleClose() {
                console.log("handleClose")
            }
        }
	}
</script>
