import CacheCleaner from "./CacheCleaner"
import ContractCleaner from "./ContractCleaner"
import SeasonMareStallionTransformer from '@/assets/js/dexie/transformers/SeasonMareStallionTransformer.js'
import store from '@/store/index.js';

export default class SeasonMareStallionCleaner extends CacheCleaner {

	async onMutation(seasonmarestallion_id, columns) {
        // si j'ai pas 
        if(!store.state.userAccess.hasSaillieAccess) {
            return false
        }

		await this.init()

        return this.db().t('season_mare_stallion')
		.then(table => {
            return table.where({
                seasonmarestallion_id: parseInt(seasonmarestallion_id),
            })
		})
        .then(col => {
            return SeasonMareStallionTransformer.process(col, 'withMareLight')
        })
        .then(sms => {
            if(sms[0]) {
                const stallion_id = sms[0].seasonmarestallion_horse
                const season_id = sms[0].mare && sms[0].mare.seasonmare_season ? sms[0].mare.seasonmare_season : null
                const mare_id = sms[0].mare && sms[0].mare.seasonmare_horse ? sms[0].mare.seasonmare_horse : null

                if(stallion_id && season_id && mare_id) {
                    return this.db().t('contract_config')
                    .then(table => {
                        return table.where({
                            contractconfig_horse: parseInt(stallion_id),
                            contractconfig_season: parseInt(season_id)
                        })
                        .first()
                    })
                    .then(contract_config => {
                        if(contract_config) {
                            return this.db().t('contract')
                            .then(table => {
                                return table.where({
                                    contract_contractconfig: contract_config.contractconfig_id
                                })
                            })
                            .then(col => {
                                return SeasonMareStallionTransformer.process(col, 'withLastAvenantUltraLight')
                            })
                            .then(contracts => {
                                if(contracts) {
                                    let contract_ids = []
                                    contracts.forEach(contract => {
                                        if(contract.last_avenant && contract.last_avenant.avenant_horse == mare_id) {
                                            contract_ids.push(contract.contract_id)
                                        }
                                    })

                                    return this.onMutationContract(contract_ids, columns)
                                }
                            })
                        }
                    })
                }
            }
        })
	}

    async onMutationContract(contracts_id, columns) {
		await ContractCleaner.inst().onMutation(contracts_id, columns)
	}
}